import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT || 'production',

    integrations: [new Integrations.BrowserTracing(), new CaptureConsole({ levels: ['error'] })],

    // tracing only for non-production envs
    tracesSampleRate: process.env.SENTRY_ENVIRONMENT ? 1.0 : 0,

    beforeSend(event) {
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: { name: 'Player', email: 'player@synthesis.is' },
          subtitle: 'Try refreshing your browser window, or going back and re-joining.',
          successMessage: 'Thank you. Try refreshing your browser window, or going back and re-joining.',
        });
      }
      return event;
    },
  });
}
